<template>
  <div>
    <!-- Task Card Header -->
    <b-card
      class="card mb-1"
      title="Detail Surat Penugasan"
    >
        <div class="mt-2">
          <b-row>
            <!-- Nama Lengkap -->
            <b-col
              lg="6"
              class="mb-1"
            >
              <label class="d-block">Nama Agenda</label>
              <b-form-input
                v-model="formData.name"
                readonly
              />
            </b-col>

            <b-col
              lg="6"
              class="mb-1"
            >
              <label class="d-block">Nomor Agenda</label>
              <b-form-input
                v-model="formData.number"
                readonly
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.number ? formErrors.number[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <b-col lg="6">
              <b-form-group
                label="Tanggal Agenda"
              >
                <b-form-input
                  v-model="formData.date"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col lg="6">
              <b-form-group
                label="File Surat"
              >
                <a
                  :href="formData.file"
                  target="_blank"
                  rel="formData.name"
                  class="btn btn-sm btn-danger"
                >Buka</a>
              </b-form-group>
            </b-col>

            <b-col
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Visibilitas"
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="formData.is_public"
                  disabled
                  :options="[
                    { text: 'Dilihat Semua Staff', value: 1 },
                    { text: 'Hanya Dilihat Oleh Peserta', value: 0 },
                  ]"
                  name="radio-options"
                />
              </b-form-group>
            </b-col>

            <b-col
              lg="12"
              class="mb-1 overflow-auto"
            >
              <b-form-group
                label="Staff"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>NO</th>
                      <th>Nama</th>
                      <th>Email</th>
                      <th>No HP</th>
                      <th>SPPD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in formData.staffs"
                      :key="index"
                    >
                      <td>{{ index+1 }}</td>
                      <td>
                        <span class="text-nowrap">
                          <b-avatar
                            :src="item.user.avatar"
                            :text="avatarText(item.user.name)"
                            variant="light-primary"
                            class="mx-1"
                          />
                          <span class="text-nowrap">{{ item.user.name }}</span>
                        </span>
                      </td>
                      <td>{{ item.user.email }}</td>
                      <td>{{ item.user.profile.phone }}</td>
                      <td>
                        <b-button v-if="item.sppd" variant="danger" size="sm" target="__blank" :href="item.sppd">Buka</b-button>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-form-group>
            </b-col>
          </b-row>
          <div />
        </div>
    </b-card>
  </div>
</template>

<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BFormFile,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'
import {
  reqGetLetterOfAssignment,
} from '@/api/admin/letterOfAssignment'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormDatepicker,
    BFormFile,
    BFormRadioGroup,
    DatePicker,
    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      blobImage: '',
      formErrors: {},

      formData: {
        name: '',
        number: '',
        date: '',
        is_public: 1,
        file: undefined,
        staffs: [],
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  async created() {
    await this.getLOA()
  },
  methods: {
    avatarText,
    getLOA() {
      reqGetLetterOfAssignment(this.$route.params.id).then(response => {
        this.formData.name = response.data.data.name
        this.formData.number = response.data.data.number
        this.formData.date = response.data.data.date
        this.formData.file = response.data.data.file
        this.formData.is_public = response.data.data.is_public ? 1 : 0
        this.formData.staffs = response.data.data.letter_of_assignment_participants
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
